@import url("https://free.bboxtype.com/embedfonts/?family=FiraGO:400,700");

body {
  font-family: "FiraGO:400";
  margin: 0;
  background-color: "gray.50";
}

.header {
  background: #3476ae 0 0 no-repeat padding-box;
  border: 1px solid #707070;
  height: 55px;
  left: 0;
  top: 0;
  font-family: "FiraGO:700";
}

.hero {
  font-family: "FiraGO:700";
}

.displayMessage {
  color: #000000;
  letter-spacing: 0;
  margin-top: 10px;
  opacity: 1;
  text-align: center;
}

.hr {
  border: 1px solid #e0e0e0;
  opacity: 1;
}

.controls {
  margin-top: 10px;
  text-align: center;
}

.footer {
  background: #eef3f8 0 0 no-repeat padding-box;
  bottom: 0;
  height: 39px;
  opacity: 1;
  position: absolute;
  width: 100%;
}

.footer-text {
  font: Regular 16px/21px Segoe UI;
  height: 21px;
  letter-spacing: 0;
  margin-top: 9px;
  opacity: 1;
  position: relative;
  text-align: center;
  width: 100%;
}

.footer-text > a {
  color: #278ce2;
  font: Regular 16px/21px Segoe UI;
  letter-spacing: 0;
  text-decoration: underline;
}

.title {
  color: #ffffff;
  font: Bold 22px/27px Segoe UI;
  letter-spacing: 0;
  margin: 13px;
  margin-left: 36px;
  opacity: 1;
  text-align: left;
}

button {
  background: #337ab7;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  font-size: medium;
  height: 35px;
  margin-right: 15px;
  width: 150px;
}

button:onfocus {
  outline: none;
}

iframe {
  border: none;
}

.report-style-class {
  height: 1500px;
}

.dateInput {
  border: 1px solid #000000;
  border-radius: 1rem;
  text-align: center;
  padding: 5px;
  margin: 3px;
}

.dataLabel {
  overflow-wrap: break-word;
}

.logo {
  max-width: 100%;
  max-height: 100%;
}

svg {
  overflow: visible;
}
